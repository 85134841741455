import React from "react";
import footerStyles from "./footer.module.css"

export default function Footer() {
    return (
        <div>
            <hr/>
            <div className={footerStyles.footer}>
                <a style={{display: "block"}} href='https://linkedin.com/in/stefanbielmeier'  rel="noreferrer" target='_blank'>
                    <img src={'/in.png'} alt="hi" className={footerStyles.image}/>
                    LinkedIn
                </a>
                <div style={{display: "block"}}>
                    <img src={'/email.png'} alt="hi" className={footerStyles.image}/>
                    firstlast@icloud.com
                </div>
            </div>  
      </div>
    )
}
