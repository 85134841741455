import React from "react";
import containerStyles from "./container.module.css"
import {Helmet} from "react-helmet"
import Footer from "./footer.js"


export default function Container({ children }) {
  return (
    <div className={containerStyles.container}>
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap" rel="stylesheet"></link>

        </Helmet>
        <h1 className={containerStyles.title}>Stefan Bielmeier</h1>
        {children}
        <Footer/>
    </div>
  )
}
